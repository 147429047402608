.main-bnr-one{
	position:relative;
  	align-items: center;
	z-index:1;
	overflow: hidden;
	
	.banner-inner {
		position:relative;
		z-index:1;
		background-color: var(--primary);
		height:930px;
		
		&::after{
			position: absolute;
			z-index: -1;
			content: "";
			height: 100%;
			width: 70%;
			top: 0;
			left: 0;
			background:var(--bg-light);
			
			@media only screen and (min-width: 2400px){
				width: 65%;
			}
		}
		.banner-content{
			position: relative;
			z-index: 1;
			margin-bottom:60px;
			
			.sub-title{
				font-weight: 500;
				font-size:20px;
				line-height: 1.2;
				margin-bottom:30px;
				color: #FE9F10;
				transition: all 0.5s ease-out 0.1s;
				transform: translateX(-15px);
				transition-delay: 0.5s;
				opacity: 0;
				display: block;
			}
			h1{
				font-family: 'Lobster';
				font-style: normal;
				font-weight: 400;
				font-size: 80px;
				line-height: 1.2;
				margin-bottom: 10px;
				transition: all 0.6s ease-out 0.1s;
				transform: translateX(-15px);
				transition-delay: 0.7s;
				opacity: 0;
			}
			p{
				font-size: 18px;
				line-height: 27px;
				max-width:500px;
				transition: all 0.7s ease-out 0.1s;
				transform: translateX(-15px);
				transition-delay: 0.9s;
				opacity: 0;
			}
			.banner-btn{
				margin-top:40px;
				transition: all 0.8s ease-out 0.1s;
				transform: translateX(-15px);
				transition-delay: 1.1s;
				opacity: 0;
			}	
		}
		.banner-media{
			position:relative;
			z-index:1;
			margin-top:70px;
			transition: all 0.5s ease-out 0.1s;
			transform: translateY(15px);
			transition-delay: 0.5s;
			opacity: 0.5;
		}
		.img1,
		.img2,
		.img3{
			position:absolute;
			z-index: 1;
		}
		.img1{
			right: 0;
			min-width: 50vw;
			width: 50vw;
			top: 0;
			opacity: 0.25;
			z-index: -1;
		}
		.img2{
			top: 156px;
			right: 147px;
			opacity: 0;
			transform: rotate(5deg);
			transition: all 0.5s ease-out 0.1s;
			transition-delay: 0.5s;
		}
		.img3{
			top: 100px;
			left: 50%;
			width: 150px;
			transform: translateX(-50%);
			animation: move1 7s infinite;
		}
		@include respond('wide-desktop'){
			.img2{
				right: 45px;
			}
		}
		@include respond('laptop'){
			height:850px;
			
			&::after{
				width: 75%;
			}
			.img2{
				right: -60px;
			}
			.banner-content{
				h1{
					font-size:70px;
					line-height:85px;
				}
			}
			.banner-media{
				margin-top:0px;
			}
		}
		@include custommq($max: 1320px) {
			.img2{
				right:-128px;
			}
		}
		@include respond('tab-land'){
			height:750px;
			
			&::after{
				width:100%;
			}
			.banner-content{
				h1{
					font-size:60px;
					line-height:75px;
				}
				p{
					font-size:16px;
					line-height:24px;
				}
			}
			.banner-media{
				img{
					height:750px!important;
					object-fit:cover;
				}
			}
			.img2,
			.img3{
				display: none;
			}
			.main-thumb1-area{
				bottom: 95px;
			}
		}
		@include respond('tab-port'){
			padding-top: 50px;
			height: 650px;
			
			.banner-content{
				margin-bottom: 0;
				
				h1{
					font-size:50px;
					line-height:65px;
				}
				.sub-title{
					margin-bottom: 15px;
				}
				.banner-btn{
					margin-top: 25px;
				}
			}
			.banner-media{
				img{
					height:600px!important;
					object-fit:cover;
				}
			}
		}
		@include respond('phone-land'){
			padding-top: 110px;
			height: 800px;
			
			.banner-content{
				margin-bottom:30px;
				
				.sub-title{
					margin-bottom: 10px;
					font-size: 16px;
				}
			}
			.banner-media{
				text-align: center;
				
				img{
					object-fit: cover;
					margin: auto;
					width: 250px;
					height:375px!important;
				}
			}
		}
		@include respond('phone'){
			.banner-content{
				h1{
					font-size:45px;
					line-height:55px;
				}
				p{
					font-size:14px;
				}
			}
		}
	}
	.main-thumb1{
		width: 612px;
		height: auto;
		
		overflow: hidden;
		
		.swiper-wrapper {
			height: auto;
		}
		.swiper-slide{
			width: 295px!important;
			opacity: 0.5;
			@include transitionMedium;
			cursor: pointer;
			
			&.swiper-slide-thumb-active{
				opacity: 1;
			}
		}
		.food-card{
			display:flex;
			align-items:center;
			.dz-media{
				width:80px;
				min-width:80px;
				border-radius:var(--border-radius-base);
			}
			.dz-content{
				margin-left:15px;
				p{
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					color: #666666;
					margin-bottom:0;
				}
			}
		}
	}
	.main-thumb1-area{
		padding: 0 50px;
		position:absolute;
		bottom:55px;
		z-index: 999;
		
		@include respond('tab-port'){
			display: none;
		}
	}
	.swiper-slide.swiper-slide-active{
		.banner-inner {
			.banner-content{
				.sub-title,
				.banner-btn,
				h1,
				p{
					transform: translateY(0);
					opacity: 1;
				}
			}
			.banner-media{
				transform: translateY(0);
				opacity: 1;
			}
			.img2{
				opacity: 1;
				transform: rotate(0);
			}
		}
	}
}

.main-bnr-one{
	.slider-pagination{
		position: absolute;
		width: auto;
		left: 50px;
		z-index: 99;
		top: 50%;
		transform: translateY(-50%);
		
		.main-button-next,
		.main-button-prev  {
			position: static;
			align-items: center;
			color:var(--primary);
			z-index: 2;
			width: 55px;
			height: 55px;
			line-height: 55px;
			text-align: center;
			font-size: 30px;
			margin: 10px 0;
			
			i{
				font-family: 'feather';
			    font-style: normal;
			}
			
			@include respond('tab-port'){
				display: none;
			}
		}
		.swiper-pagination-bullet{
			font-weight: 400;
			font-size: 24px;
			margin: 10px 0;
			text-align: center;
			opacity: 1;
			color: var(--rgba-primary-4);
			font-family: 'Lobster';
			display: block;
			height: 55px;
			width: 55px;
			line-height: 55px;
			border-radius: 0;
			background: transparent;
			@include transitionMedium;
			
			&.swiper-pagination-bullet-active{
				color: var(--primary);
			}
		}
		@include respond('laptop'){
			left: 20px;
		}
		@include respond('tab-land'){
		    right: 20px;
			left: auto;
		}
		@include respond('tab-port'){
			right: 10px;
			
			.swiper-pagination-bullet{
				font-size: 18px;
				height: 40px;
				width: 40px;
				line-height: 40px;
			}
		}
		@include respond('phone'){
		    right: 10px;
			top: auto;
			bottom: 15px;
			transform: translate(0);
		}
	}
}
@keyframes move1{
	0%{
		-webkit-transform: translate(0);
		transform: translate(0);
	}
	25%{
		-webkit-transform: translate(-15px, -15px);
		transform: translate(-15px, -15px);
	}
	50%{
		-webkit-transform: translate(-8px, -8px);
		transform: translate(-8px, -8px);
	}
	75%{
		-webkit-transform: translate(-15px, 8px);
		transform: translate(-15px, 8px);
	}
	to{
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}

@media only screen and (min-width: 1281px){
	[data-layout="boxed"]{
		.main-bnr-one{
			.banner-inner::after {
				width: 80%;			
			}
			.slider-pagination {
				left: auto;
				right: 20px;
			}
			.slider-pagination .swiper-pagination-bullet {
				color: #fff;
			}
			.slider-pagination{
				.swiper-pagination-bullet.swiper-pagination-bullet-active {
					color: #fff;
					opacity: 0.5;
				}
				.main-button-next,
				.main-button-prev{
					color: #fff;			
				}
			}
		}
	}
}

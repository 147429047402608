//MEDIA QUERY MANAGER
// 0 - 500: Phone 
// 500 - 900: Tablet portrait
// 900 - 1200: Tablet landscape
// 1200 - 1800: Normal styles
// 1800+ : Big Desktop
// 1em = 16px
// The smaller device rules always should write below the bigger device rules
// Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component

@mixin respond($breakpoint) {
    @if($breakpoint=="phone") {
        @media only screen and (max-width: 575px) {
            @content;
        }
    }
    @if($breakpoint=="phone-land") {
        @media only screen and (max-width: 767px) {
            @content;
        }
    }
    @if($breakpoint=="tab-port") {
        @media only screen and (max-width: 991px) {
            @content;
        }
    }
	@if($breakpoint=="tab-port-min") {
        @media only screen and (min-width: 991px) {
            @content;
        }
    }
    @if($breakpoint=="tab-land") {
        @media only screen and (max-width: 1280px) {
            @content;
        }
    }
	@if($breakpoint=="laptop") {
        @media only screen and (max-width: 1480px) {
            @content;
        }
    }
    @if ($breakpoint=="desktop") {
        @media only screen and (min-width: 1200px) {
            @content;
        }
    }
	@if($breakpoint=="wide-desktop") {
        @media only screen and (max-width: 1680px) {
            @content;
        }
    }
	@if($breakpoint=="wide-desktop-min") {
        @media only screen and (min-width: 1680px) {
            @content;
        }
    }
    @if($breakpoint=="big-desktop") {
        @media only screen and (min-width: 1800px) {
            @content;
        }
    }
}

//don't use it untill you need this too much
@mixin custommq($min: null, $max: null) {
    @if ($min !=null and $max !=null) {
        @media only screen and (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
    @if ($min==null and $max !=null) {
        @media only screen and (max-width: $max) {
            @content;
        }
    }
    @if ($min !=null and $max==null) {
        @media only screen and (min-width: $min) {
            @content;
        }
    }
}
// transition
@mixin transitionNone{
	-webkit-transition: none;
	-ms-transition: none;
	transition: none;
}
@mixin transitionMedium{
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}
@mixin transitionSlow{
	-webkit-transition: all 0.8s;
	-ms-transition: all 0.8s;
	transition: all 0.8s;
}
@mixin transitionFast{
	-webkit-transition: all 0.2s;
	-ms-transition: all 0.2s;
	transition: all 0.2s;
}
@mixin translateZ{
	transform:translate(-50%, -50%);
	-moz-transform:translate(-50%, -50%);
	-webkit-transform:translate(-50%, -50%);
	-ms-transform:translate(-50%, -50%);
	-o-transform:translate(-50%, -50%);
}

@mixin transformScale{
	transform:scale(1);
	-moz-transform:scale(1);
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	-o-transform:scale(1);
}
@mixin transformScaleXS{
	transform:scale(0.8);
	-moz-transform:scale(0.8);
	-webkit-transform:scale(0.8);
	-ms-transform:scale(0.8);
	-o-transform:scale(0.8);
}
@mixin transformScaleXS1{
	transform:scale(0.5);
	-moz-transform:scale(0.5);
	-webkit-transform:scale(0.5);
	-ms-transform:scale(0.5);
	-o-transform:scale(0.5);
}
@mixin transformScaleSM{
	transform:scale(1.2);
	-moz-transform:scale(1.2);
	-webkit-transform:scale(1.2);
	-ms-transform:scale(1.2);
	-o-transform:scale(1.2);
}
@mixin transformScaleMD{
	transform:scale(1.5);
	-moz-transform:scale(1.5);
	-webkit-transform:scale(1.5);
	-ms-transform:scale(1.5);
	-o-transform:scale(1.5);
}
@mixin beforeAfter{
	content: '';
	position: absolute;	
}
@mixin beforeAfterCenter{
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
@mixin absoluteCenter{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
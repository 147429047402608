.pagination{
	justify-content:center;
	.page-item{
		margin: 0 8px;
		.page-link {
			height: 45px;
			min-width: 45px;
			line-height: 45px;
			padding: 0;
			border: 0;
			text-align: center;
			@include transitionMedium;	
			box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
			background-color:#fff;
			border-radius:8px;
			&.current,
			&.active,
			&:hover{
				background-color: var(--primary);
				border-color: var(--primary);
				color:$white;
			}
		}
	}

	&.pagination-md{
		.page-item{
			.page-link{
				width: 30px;
				height: 30px;
				line-height: 30px;
				font-size: 14px;
				text-align:center;
			}
		}
	}
	&.pagination-sm{
		.page-item{
			.page-link{
				padding: 0;
				min-width: 40px;
				height: 40px;
				line-height: 40px;
				font-size: 14px;
				text-align: center;
			}
		}
	}
	&.pagination-xs{
		.page-item{
			.page-link{
				padding: 0;
				min-width: 32px;
				height: 32px;
				line-height: 32px;
				font-size: 12px;
				text-align: center;
			}
		}
	}
	&-primary{
		.page-item{
			.page-link{
				background:var(--rgba-primary-1);
				border-color:var(--rgba-primary-1);
				color:#222222;
					
				&:hover,
				&.active{
					color: #fff;
					background:var(--primary);
					border-color:var(--primary);
					box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 193, 180, 0.3);
				}
			}
		}
	}
	&-danger{
		.page-item{
			.page-link{
				background:$danger-light;
				border-color:$danger-light;
				color:$danger;
				&:hover,
				&.active{
					background:$danger;
					border-color:$danger;
					color:$white;
					box-shadow:0 0.625rem 1.25rem 0rem rgba($danger, 0.2);
				}
			}
		}
	}
	&-info{
		.page-item{
			.page-link{
				background:$info-light;
				border-color:$info-light;
				color:$info;
				&:hover,
				&.active{
					background:$info;
					border-color:$info;
					color:$white;
					box-shadow:0 0.625rem 1.25rem 0rem rgba($info, 0.2);
				}
			}
		}
	}
	&.style-1{
		display: flex;
		align-items: center;
		
		.main-button-next{
			top:auto !important;
			bottom:30%;
		}
		.main-button-next,
		.main-button-prev  {
			position: absolute;
			top: 30%;
			align-items: center;
			color:var(--primary);
			z-index: 2;
			width:55px;
			height:55px;
			line-height:55px;
			text-align:center;
			left: 105px;
			font-size: 30px;
			margin:10px 0;
			
			i{
				font-family: 'feather';
			    font-style: normal;
			}
			@include respond('laptop'){
				left:23px;
			}
			@include custommq($max: 1320px) {
				left:10px;
			}
			@include respond('tab-land'){
				display:none;
			}
		}
		&.left-align{
			.main-button-next, .main-button-prev  {
				left:10px;
				top:30%;
			}
			.main-button-next{
				bottom:30%;
			}
		}
	}
}

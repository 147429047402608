.map-iframe{
	iframe{
		width: 100%;
	}
	&.style-1{
		position: relative;
		
		&::after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #222;
			mix-blend-mode: color;
			z-index: 0;
			pointer-events: none;
		}
		iframe{
			height:400px;
			margin-bottom:-10px;
			@include respond('tab-port'){
				height:350px;			
			}
			@include respond('phone'){
				height:300px;
			}
		}
	}
}

.contact-box {
	padding-right: 100px;
	margin-bottom: 70px;
	
	@include respond('phone'){
		padding-right: 0;
		margin-bottom: 30px;
	}
}
.widget_working {
	padding: 30px;
	background: rgba(255 ,255 ,255 ,0.1);
	border-radius: 10px;
	margin-bottom: 0;
	.head{
		margin-bottom:40px;
		.title{
			font-size:24px;
		}
		p{
			font-size: 15px;
			margin-bottom:0;
			opacity: 0.7;
		}
	}
	ul{
		li {
			color: #fff;
			display: flex;
			margin-bottom: 25px;
			padding-left: 40px;
			position:relative;
			p{
				margin-bottom:0;
			}
			i{
				position: absolute;
				font-size: 30px;
				left: 0;
				top: 5px;
				color: var(--secondary);
				width: 30px;
				height: 30px;
				line-height: 30px;

			}
			&:last-child{
				margin-bottom:0;
			}
		}
	}
	@include respond('tab-port'){
		margin-bottom: 30px !important;
	}
	@include respond('phone'){
		padding: 15px;
	}
}
.contact-area{
	padding: 50px 50px 20px;
	border-radius: 10px;
	background-color:$dark;
	.contact-head{
		margin-bottom:30px;
	}
	@include respond('tab-land'){
		padding:30px 30px 0;
	}
	@include respond('phone'){
		border-radius: 0px;
	}
}



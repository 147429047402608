// video-bx
.video{
	z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	width:87px;
	height:87px;
	line-height:87px;
	background-color:var(--primary);
	color:#fff;
	border-radius:50%;
	text-align:center;
	font-size:24px;
	@include transitionMedium;
	
	&:hover{
		transform: translate(-50%, -50%) scale(1.2);
	}
	i{
		color:#fff;
	}
	@include respond ('tab-land'){
		width:70px;
		height:70px;
		line-height:70px;
		font-size:18px;
	}
	@include respond ('phone-land'){
		width:55px;
		height:55px;
		line-height:55px;
		font-size:16px;
	}
	@include respond ('phone'){
		svg{
			width:50px;
			height:50px;
		}
	}
	&.style-1{
		width: 60px;
		height: 60px;
		line-height: 60px;
		font-size: 18px;
	}
}


@keyframes animationSignal1 {
  /*Video Popup*/
	0% {
		opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.5);
		-moz-transform:  scale(1.5);
		-ms-transform:  scale(1.5);
		-o-transform:  scale(1.5);
		-webkit-transform:  scale(1.5);
		opacity: 0;
	}
}
@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
	0% {
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.5);
		-moz-transform:  scale(1.5);
		-ms-transform:  scale(1.5);
		-o-transform:  scale(1.5);
		-webkit-transform:  scale(1.5);
		opacity: 0;
	}
}
@keyframes animationSignal2 {
  /*Video Popup*/
	0% {
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.9);
		-moz-transform:  scale(1.9);
		-webkit-transform:  scale(1.9);
		-ms-transform:  scale(1.9);
		-o-transform:  scale(1.9);
		opacity: 0;
	}
}
@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
	0% {
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.9);
		-moz-transform:  scale(1.9);
		-webkit-transform:  scale(1.9);
		-ms-transform:  scale(1.9);
		-o-transform:  scale(1.9);
		opacity: 0;
	}
}


.icon-bx-wraper{
	&.style-2{
		background-color: #fff;
		overflow: hidden;
		display: flex;
		align-items: center;
		@include transitionMedium;
		
		.icon-bx{
			background-color:var(--rgba-primary-1);
			width:100px;
			height:100px;
			min-width:100px;
			border-radius:100%;
			line-height:100px;
			text-align:center;
			margin-right:20px;
			
			.icon-cell{	
				color:var(--primary);
				@include transitionMedium;
				i{
					font-size:45px;
				}
			}
		}
		.icon-content{
			p{
				font-size:15px;
				font-weight:300;
				font-family:var(--font-family-title);
			}
		}
		&:hover,
		&.active{
			.icon-bx{
				background-color:var(--primary);
				
				.icon-cell{
					color:#fff;
					
					i{
						animation: shake 1s;				
					}
				}
			}
		}
		@include respond('tab-land'){
			.icon-bx{
				width:80px;
				min-width:80px;
				height:80px;
				line-height:80px;
				margin-right:15px;
				.icon-cell{	
					i{
						font-size:35px;
					}
				}
			}
			.icon-content{
				.dz-title{
					font-size:18px;
				}
			}
		}
	}
}

.icon-wrapper2{
	position:relative;
}

.icon-wrapper2 > div{
	@include respond('tab-port-min'){
		&:not(:nth-child(3)){
			border-right: 1px dashed rgba(102, 102, 102, 0.2);			
		}
		&:last-child{
			border-right: none;
		}
	}
}
// Google Font
// font-family: 'Poppins', sans-serif;
// font-family: 'Noto+Sans', sans-serif;
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


// Line Awesome
@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url('../icons/font-awesome/css/all.min.css');
@import url('../icons/themify/themify-icons.css');
@import url('../icons/flaticon/flaticon_swigo.css');
@import url('../icons/feather/css/iconfont.css');

.list-circle,
.list-angle-double,
.list-square,
.list-square-check{
	li{
		position: relative;
		padding: 6px 5px 6px 22px;
		&::before{
			position: absolute;
			left: 0;
			top: 7px;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
		}
	}
	&.primary{
		li{
			&::before{
				color: var(--primary);
			}
		}
	}
}

.list-check{
	li{
		position: relative;
		padding: 6px 5px 6px 30px;
		
		&::before{
			font-size:20px;
			content: "\e83f";
			font-family:'feather' !important;
			font-style: normal;
			position: absolute;
			left: 0;
			top: 5px;
		}
	}
	&.primary{
		li{
			&::before{
				color: var(--primary);
			}
		}
	}
}

.avatar-list{
	&.avatar-list-stacked {
		.avatar {
			margin-right: -17px;
			display: inline-block;
			
			&:last-child{
				margin-right:0;
			}
			span{
				background-color:var(--primary);
				color:#fff;
				height: 2.375rem;
				line-height: 2.375rem;
				width: 2.375rem;
				border-radius:100%;
				font-size:12px;
				display:block;
				text-align:center;
			}
			img{
				object-fit: cover;
				border-radius:100%;
				border: 2px solid #fff;
				height: 2.375rem;
				width: 2.375rem;
			}
			&:hover {
				img{
					z-index: 1; 
				}
			}		  
		}
	}
}

// Error Page
.error-page{
	position:relative;
	height: 100vh;
    display: flex;
    align-items: center;
    min-height: 700px;
	
	.particles-js-canvas-el{
		position: absolute;
		left: 0;
		z-index: 1;
		top: 0;
	}
	
	&::after{
		content:"";
		position:absolute;
		width:100%;
		height:100%;
		background: rgba(38, 38, 38, 0.8);		
		top:0;
		left:0;
	}
	.inner-content{
    	padding-top: 150px;
		position: relative;
		z-index: 9;																		
    }
	.error-head{
		font-family: 'Lobster';
		margin: 0 auto 20px;
		font-weight: 400;
		font-size: 140px;
		line-height: 1;
		font-style: normal;
		text-align:center;
		color: #FFFFFF;
	}
	.error-para{
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 600;
		font-size: 35px;
		line-height:1.5;
		color: #EFEFEF;
		margin-bottom:5px;
	}
	p{
		font-size: 18px;
		line-height: 1.3;
		margin-bottom: 40px;
		color: rgba(255,255,255,0.5);
	}
	@include respond('laptop'){
		min-height: 650px;
	}
	@include respond('tab-land'){
		.error-para{
			font-size:30px;
			line-height:1.4;
			margin-bottom:10px;
		}
		p{
			margin-bottom:25px;
		}
	}
	@include respond('tab-port'){
		min-height:  500px;
		.inner-content {
	    	padding-top: 80px;
	    }
	}
	@include respond('phone-land'){
		.error-head{
			font-size:120px;
		}
		.error-para{
			font-size: 22px;
			line-height: 1.3;
		}
		
	}
	@include respond('phone'){
		min-height: 450px;
	}
}

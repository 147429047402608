.section-wrapper-1{
	position:relative;
	z-index:1;
	&::after{
		content:"";
		position:absolute;
		z-index:-1;
		background-color: rgba(21, 21, 21, 0.7);
		width:100%;
		height:100%;
		top:0;
		left:0;
		backdrop-filter: blur(6px);
	}
}

.section-wrapper-2{
	position:relative;
	.bg1,.bg2{
		position:absolute;
	}
	.bg1{
		bottom:0;
		left:-275px;
	}
	.bg2{
		top:100px;
		right:40px;
	}
	&.right{
		.bg1{
			bottom:0;
			right:-355px;
			left:auto;
		}
	}
	@include respond('wide-desktop'){
		.bg1{
			left:-320px;
		}
		.bg2{
			top: 90px;
			right: 15px;
		}
	}
	@include respond('laptop'){
		.bg1{
			display:none;
		}
		.bg2{
			top: 28px;
			right: 0px;
		}
		&.right{
			.bg1{
				display:none;
			}
		}
		@include respond('tab-land'){
			.bg2{
				display:none;
			}
		}
	}
}

.section-wrapper-3{
	position:relative;
	z-index:1;
	&::before{
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color:var(--bg-light);
		z-index:-1;
	}
	&::after{
		content: "";
		height: 200px;
		width: 100%;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index:-1;
	}
	.inner-section-wrapper{
		margin-bottom:80px;
	}
	.bg1,.bg2{
		position:absolute;
		z-index:-1;
	}
	.bg2{
		top:255px;
		right:85px;
	}
	.bg1{
		bottom:300px;
		left:70px;
	}
	@include respond('wide-desktop'){
		.bg2{
			top: 120px;
			right: 40px;
		}
		.bg1{
			bottom: 280px;
			left: 30px;
		}
		.inner-section-wrapper{
			margin-bottom:60px;
		}
	}
	@include respond('laptop'){
		.bg2{
			top: 50px;
			right: 30px;
		}
		.bg1{
			bottom: 350px;
			left: 10px;
		}
	}
	@include respond('tab-land'){
		.inner-section-wrapper{
			margin-bottom:60px;
		}
		.bg1,.bg2{
			display:none;
		}
	}
	@include respond('phone-land'){
		.inner-section-wrapper{
			margin-bottom:45px;
		}
	}
	
}

.section-wrapper-4{
	margin-left:100px;
	margin-right:100px;
	background-color:var(--bg-light);
	border-radius:30px;
	@include respond('laptop'){
		margin-left:0px;
		margin-right:0px;
		border-radius:0;
	}
}

.section-wrapper-5{
	margin-left:100px;
	margin-right:100px;
	z-index:1;
	position:relative;
	border-radius:30px;
	&::after{
		content:"";
		position:absolute;
		top:0;
		left:0;
		backdrop-filter: blur(6px);
		background-color:rgba(21, 21, 21, 0.7);
		width:100%;
		height:100%;
		z-index:-1;
	}
	@include respond('laptop'){
		margin-left:0px;
		margin-right:0px;
		border-radius:0;
	}
}

.section-wrapper-6{
	position:relative;
	z-index:1;
	&::after{
		content: "";
		height: 125px;
		width: 100%;
		background-color: rgba(34, 34, 34, 0.05);
		position: absolute;
		bottom: 0;
		left: 0;
		z-index:-1;
	}
	.inner-section-wrapper{
		margin-bottom:80px;
	}
	
	@include respond('phone-land'){
		.inner-section-wrapper{
			margin-bottom:45px;
		}
	}
	@include respond('phone'){
		.inner-section-wrapper{
			margin-bottom:25px;
		}
	}
}

.section-wrapper-7{
	position:relative;
	z-index:1;
	&::after{
		content: "";
		width: 850px;
		height: 850px;
		background-color: #FFEEBB;
		position: absolute;
		top: 110px;
		right: -19%;
		z-index:-2;
		filter: blur(9px);
		border-radius:100%;
	}
	.inner-section-wrapper{
		margin-bottom:50px;
	}
	.bg1{
		position: absolute;
		top: 85px;
		left: -110px;
	}
	.bg2{
		position:absolute;
		right:-54px;
		top:30px;
		z-index:-1;
	}
	@include respond('laptop'){
		&::after{
			width:700px;
			height:700px;
			top: 45px;
			right: -22%;
		}
		.bg1{
			display:none;
		}
		.bg2{
			width:600px;
		}
	}
	@include respond('tab-land'){
		&::after{
			width:600px;
			height:600px;
		}
		.bg2{
			width:500px;
		}
	}
	@include custommq ($max:1024px){
		&::after{
			display:none;
		}
		.bg2{
			top:auto;
			bottom:0;
		}
	}
	@include respond('phone-land'){
		.bg2{
			display:none;
		}
		.inner-section-wrapper{
			margin-bottom:0px;
		}
	}
}

.section-wrapper-8{
	position:relative;
	background-color:rgba(34,34,34,0.04);
	border-radius: 30px;
	margin-left:100px;
	margin-right:100px;
	margin-top:270px;
	margin-bottom:100px;
	padding-left: 30px;
    padding-right: 30px;
	
	.inner-section-wrapper{
		margin-bottom:80px;
		margin-top: -215px;
	}
	@include respond('laptop'){
		margin-left:50px;
		margin-right:50px;
		margin-top:190px;
		margin-bottom:70px;
		
		.inner-section-wrapper{
			margin-top:-170px;
			margin-bottom:60px;
		}
	}
	@include respond('tab-port'){
		background-color:#fff;
		margin: 0;
		
		.inner-section-wrapper{
			margin-top:0;
			margin-bottom:30px;
		}
	}
	@include respond('phone-land'){
		padding-left: 15px;
		padding-right: 15px;
		
		.inner-section-wrapper{
			margin-bottom:45px;
		}
	}
}
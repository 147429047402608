// ft-clients
.site-footer {
	.bg1,
	.bg2{
		position:absolute;
	}
	.bg1{
		left:0;
		bottom:10px;
	}
	.bg2{
		right:10px;
		top:15px;
	}
	@include respond('laptop'){
		.bg1,.bg2{
			display:none;
		}
	}
	.footer-bottom {
		position: relative;
		z-index: 1;
		p{
			font-weight: 400;
			font-size: 15px;
			line-height: 22px;
			color: #999999;
			margin-bottom:0;
		}
		.copyright-text {
			color: #999999;
			font-size: 15px;
			font-weight: 400;
			line-height:1.1;
			a {
				color: var(--primary);
			}
		}
	}
	.widget_services ul{
		li{
			padding: 0px 0 20px 0px;
			a{
				color:#CCCCCC;
				font-weight:400;
				&::before{
					display:none;
				}
			}
		}
	}
	&.style-1{
		.footer-top{
			.footer-title{
				color:#fff;
			}
		}
		
		@include respond('tab-land'){
			.footer-top{
				.footer-title{
					font-size: 20px;
					margin-bottom:20px!important;
				}
			}
			.footer-bottom {
				p{
					font-size:14px;
				}
				.copyright-text{
					font-size:14px;
				}
			}
		}
		@include respond('phone-land'){
			.footer-bottom {
				.copyright-text{
					display: block;
					margin-top: 15px;
				}
			}
		}
	}
}
.footer-title{
	margin-bottom:30px!important;
	font-weight: 600;
	font-size: 24px;
	line-height: 1.1;
	letter-spacing: 0.01em;
	text-transform: uppercase;
}
.swiper-pagination {
	position:relative;
	width: auto;
	display: flex;
	align-items: center;
	
	&.style-1 {
		display: inline-block;
		position:absolute;
		bottom:10px;
		.swiper-pagination-bullet {
			width: 10px;
			height: 10px;
			text-align: center;
			font-size: 12px;
			opacity: 1;
			border-radius:30px;
			background: #fff;
			border: 1px solid var(--primary);
		}

		.swiper-pagination-bullet-active {
			background: var(--primary);
		}
	}
}
.swiper-button-disabled{
	opacity: 0.5;
	cursor: not-allowed;
}
.num-pagination {
    display: flex;
    align-items: center;
}

.btn-next, 
.btn-prev{
	position: static;
    width: 48px;
    height: 48px;
    line-height: 48px;
    border-radius: 0px;
    color: #fff;
    background: var(--primary);
    font-size: 15px;
    z-index: 1;
    text-align: center;
    margin: 0 10px;
	display: inline-block;
	box-shadow: 0px 10px 30px 0px var(--rgba-primary-3);
	@include transitionMedium;
	border-radius:10px;
	
	&:hover{
		background:$dark;
		color:#fff;
		box-shadow: 0px 10px 30px 0px rgba(34, 34, 34, 0.2);
	}
	&::after{
		content:none;	
	}
	&.dark {
		&:hover{
			background:#fff;
			color:#000;
			box-shadow: 0px 10px 30px 0px rgba(34, 34, 34, 0.2);
		}
	}
	i{
		font-size: 20px;
		line-height: inherit;
	}
	
	@include custommq($max: 1199px) {
		width: 35px;
		height: 35px;
		line-height: 35px;
		i{
			font-size: 16px;
		}
	}
	@include respond('phone'){
		width: 30px;
		height: 30px;
		line-height: 30px;
		margin: 0 2px 2px;
		i{
			font-size: 15px;
		}
	}
}

.swiper-visible{
	overflow: unset;
	
	.swiper-slide{
		opacity:0;
		@include transitionMedium;	
	}
	.swiper-slide-active,
	&:not(.swiper-single) .swiper-slide-next,
	&:not(.swiper-single) .swiper-slide-visible:not(.swiper-slide-duplicate-prev){
		opacity: 1;
	}
	&.swiper-item-4 .swiper-slide-next + .swiper-slide,
	&.swiper-item-4 .swiper-slide-next + .swiper-slide + .swiper-slide{
		opacity: 1;
	}
}
.menu-align{
	display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-prev-long,
.btn-next-long{
	z-index:99;
    display: flex;
    justify-content: center;
    align-items: center;
	font-size:18px;
	@include transitionFast;
	
	i{
		color:#fff;
		font-size:16px;
		@include transitionFast;	
	}
	&::before,
	&::after{
		content:"";
		position:absolute;
		z-index: -1;
		@include transitionFast;	
	}
	&::before{
		width:30px;
		border-radius:50px;
		height:30px;
		line-height:30px;
		background-color:var(--primary);
	}
	&::after{
		width:40px;
		height:2px;
		background-color:#000;
	}
	&:hover:not(.swiper-button-disabled){
		i{
			color: var(--primary);
		}
		&::after{
			z-index: 1;
			width: 10px;
			background-color: var(--primary);
		}
		&::before{
			border: 1.5px dashed var(--primary);
			background-color: transparent;
			height: 50px;
			width: 50px;
			line-height: 50px;
			animation: dzRotate360 10s linear infinite;
		}
	}
}
.btn-prev-long{
	&::before{
		left:-14px;	
	}
	&::after{
		left: 10px;
		z-index: -2;
	}
	
	&:hover:not(.swiper-button-disabled){
		i{
			margin-left: 4px;
		}
	}
}
.btn-next-long{
	&::before{
		right:-14px;	
		left:auto;
	}
	&::after{
		right: 10px;
		left:auto;
		z-index: -2;
	}
	&:hover:not(.swiper-button-disabled){
		i{
			margin-right: 4px;
		}
	}
}
.swiper-btn-lr{
	position:relative;
	
	.btn-prev-long,
	.btn-next-long,
	.btn-prev,
	.btn-next{
		top: 50%;
		position:absolute;
		transform: translateY(-50%);
		right:-100px;
		
	}
	.btn-prev-long,
	.btn-prev{
		left:-100px;
		right:auto;
	}
	@include respond('laptop'){
		.btn-prev-long,
		.btn-prev{
			left:-60px;
		}
		.btn-next-long,
		.btn-next{
			right:-60px;
		}
	}
	@include respond('tab-land'){
		.btn-prev-long,
		.btn-next-long{
			position: static;
			transform: translate(0);
			margin: -10px 50px 0;
		}
	}
	.btn-prev-1,.btn-next-1{
		top: 50%;
		position:absolute;
		transform: translateY(-50%);
		right:0;
		background-color:rgba(34, 34, 34,0.1);
		border-radius: var(--border-radius-base);
		height: 80px;
		width:27px;
		line-height:80px;
		text-align:center;
		color:#222222;
		font-size:14px;	
		@include transitionMedium;
		&:hover{
			background-color:var(--primary);
			color:#fff;
		}
	}
	.btn-prev-1{
		left:0;
		right:auto;
	}
}
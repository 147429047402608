.icon-bx-wraper{
	&.style-3{
		border-radius: 10px;
		background:rgba(34,34,34,0.05);
		text-align:center;
		padding:30px 12px;
		border: 2px solid transparent;
		@include transitionMedium;
		height:100%;
		display:flex;
		flex-direction:column;
		
		.icon-bx{
			width: 95px;
			height: 95px;
			line-height: 95px;
			border-radius:100%;
			text-align:center;
			background:var(--primary);
			margin-left:auto;
			margin-right:auto;
			margin-bottom:20px;
			
			.icon-cell{
				i{
					font-size:50px;
					line-height:100px;
					color:#fff;
				}
			}
		}
		.icon-content{
			margin-top:auto;
			p{
				font-size:14px;
			}
		}
		&:hover{
			transform: translateY(-10px);
			border: 2px solid var(--primary);
			background-color:#fff;
			box-shadow:0px 15px 55px rgba(34, 34, 34, 0.15);
			
			.icon-bx{
				.icon-cell{
					i{
						animation: shake 1s;				
					}
				}
			}
		}
		
		@include respond('tab-land'){
			.icon-content{
				.title{
					font-size:18px;
				}
			}
		}
		@include respond('tab-port'){
			.icon-content{
				.title{
					font-size:20px;
				}
			}
		}
	}
}


// Clients Logo
.clients-logo{
    height: 50px;
	overflow:hidden;
	position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
	
	img{
		width: 135px;
	}
	&:hover{
		img{
			-webkit-animation: logoUpToDown 0.4s forwards;
			-moz-animation: logoUpToDown 0.4s forwards;
			animation: logoUpToDown 0.4s forwards;
		}
	}
	@include respond ('phone'){
		margin: 0 25px;
	}
}
@keyframes logoUpToDown{
	49% {
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
	}
	50%{
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	51%{
		opacity: 1;
	}
}

.dropdown-item{
	&.active,
	&:active {
		background-color: var(--primary);
	}
}


.service-tags {
	a {
		padding: 10px 25px;
		border:1px solid lightgray;
		background-color:#fff;
		color: #000;
		display: inline-block;
		margin: 9px;
		@include transitionMedium;
		&:hover {
			background-color:var(--primary);
			border-color: var(--primary);
			color: #fff;
			box-shadow: 0px 5px 15px -5px var(--primary);
		}
	}
}

.dzFormMsg,
.dzSubscribeMsg{
	position: fixed;
    right: 5%;
    bottom: 20px;
    z-index: 9999999;
    
	.alert{
	    margin: 0;
	    position: relative;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
    }
    @include custommq ($max:1199px){
    	right: 9%;
    	bottom: 17px;
    }
    @include respond ('phone-land'){
		right: 5%;
    	bottom: 18%;
		width: 90%;
	} 
    @include respond ('phone'){
    	bottom: 10%;
	} 
}

.calculator-listing{
	.dzFormBmi .dzFormInner{
		position: static;
		padding: 20px 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.05);
		text-align: center;
		box-shadow: 0px 8px 24px rgba(149, 157, 165,0.2);
		border-radius: 10px;
		margin: 0;
		@media only screen and (max-width: 768px){
			padding: 20px 25px;
		}
	}
}


.video-box-sec {
	&::after {
		content: "";
	    // background-image: url(../images/background/bg-pattern1.svg);
	    position: absolute;
	    left: 10px;
	    width: 95px;
	    bottom: -110px;
	    animation: move4 5s infinite;
	    height: 230px;
	}
	&::before {
		content: "";
	    // background-image: url(../images/background/bg-pattern2.svg);
	    position: absolute;
	    right: 100px;
	    width: 43px;
	    animation: move4 3.5s infinite;
	    bottom: -35px;
	    height: 160px;
	}
}
.schedule-section {
	&::after {
		content: "";
	    // background-image: url(../images/background/bg-pattern2.svg);
	    position: absolute;
	    left: 50px;
	    width: 43px;
	    animation: move4 5s infinite;
	    top: 85px;
	    height: 160px;
	    @include respond('wide-desktop'){
			left: 10px;
		}
	    @include respond('laptop'){
			display: none;
		}
	}
	.section-head {
		.title {
			line-height: 1.6 !important;
			@include respond('tab-land'){
				line-height: 1.3 !important;
			}
		}
	}
}
.blog-section {
	&::after {
		content: "";
	    // background-image: url(../images/background/bg-pattern1.svg);
	    position: absolute;
	    right: 30px;
	    top: 50px;
	    width: 95px;
	    animation: move4 4.5s infinite;
	    height: 230px;
	    @include respond('wide-desktop'){
			right: 10px;
		}
	    @include respond('laptop'){
			display: none;
		}
	}
}


.overlay-content-box {
	position: relative;
	&::before {
	    content: "";
	    // background-image: url(../images/banner/bnr-3-s2.svg);
	    position: absolute;
        transform: rotateX(180deg) translateX(-100%);
	    left: -15px;
	    width: 40px;
	    bottom: 0px;
	    height: 90px;
	    @include respond('phone'){
			display: none;
		}
	}
}
.overlay-section {
	&::after {
		content: "";
	    // background-image: url(../images/background/bg-pattern2.svg);
	    position: absolute;
	    left: 75px;
	    top: 200px;
	    width: 43px;
	    height: 160px;
	    animation: move4 5s infinite;
	    @include custommq ($max:1800px){
	    	left: 35px;
	    }
	    @include respond('wide-desktop'){
			left: 10px;
		}
		@include respond('laptop'){
			display: none;
		}
	}
	&::before {
		content: "";
	    // background-image: url(../images/background/bg-pattern1.svg);
	    position: absolute;
	    right: 50px;
	    animation: move4 4s infinite;
	    top: 65px;
	    width: 95px;
	    height: 230px;
	    @include custommq ($max:1199px){
	    	display: none;
	    }
	}
}
.contact-section{
	padding-top: 60px;
	
	@include respond('phone'){
		padding-top: 35px;
	}
}

//team-detail
.team-detail{
	position:relative;
	display:flex;
	overflow:hidden;
	align-items:center;
	margin-bottom:55px;
	.dz-media{
		width:500px;
		min-width:500px;
	}
	.dz-info{
		width:100%;
		margin-left:50px;
		.head{
			padding-bottom:18px;
			margin-bottom:15px;
			border-bottom: 2px solid #C6C6C6;
			.title{
				font-size: 50px;
				line-height:1.1;
			}
			.sub-title{
				font-weight: 400;
				font-size: 18px;
			}
		}
		p{
			font-size: 18px;
			width:580px;
			margin-bottom:20px;
		}
		.team-info{
			margin-bottom:18px;
			li{
				font-weight: 400;
				font-size: 18px;
				margin-bottom:10px;
				strong{
					font-weight: 400;
					color:var(--title);
					margin-right:15px;
				}
			}
		}
		.dz-social-icon{
			ul{
				margin-right: -20px;
				li{
					display:inline-block;
					padding:0 5px;
					margin-right: 20px;
					a{
						font-size:20px;
						i{
							color:#666666;
						}
					}
				}
			}
		}
	}
	@include respond('tab-land'){
		margin-bottom:40px;
		.dz-info{
			.head{
				.title{
					font-size:35px;
				}
			}
			p{
				font-size: 16px;
				width:100%;
				margin-bottom:15px;
			}
			.team-info{
				li{
					font-size:16px;
				}
			}
		}
	}
	@include respond('tab-port'){
		.dz-media{
			width:250px;
			min-width:250px;
			height:350px;
			img{
				height:100%;
				object-fit:cover;
			}
		}
		.dz-info{
			margin-left:30px;
			.head{
				padding-bottom: 10px;
				margin-bottom: 10px;
				.title{
					font-size:25px;
				}
			}
			p{
				font-size:14px;
			}
			.dz-social-icon{
				ul{
					li{
						a{
							font-size:16px;
						}
					}
				}
			}
		}
	}
	@include respond('phone-land'){
		display:block;
		margin-bottom:20px;
		.dz-media{
			width:100%;
			min-width:100%;
			height:100%;
		}
		.dz-info{
			margin-left:0;
			margin-top:30px;
			.head{
				.title{
					margin-bottom:0;
				}
			}
		}
	}
}

.team-bio{
	margin-bottom:55px;
	@include respond('tab-land'){
		margin-bottom:30px;
	}
	@include respond('phone-land'){
		margin-bottom:20px;
	}
}
.dish-gallery{
	padding-left: 0;
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	
	li{
		margin-bottom: 20px;
		z-index:1;
		padding-left: 10px;
		padding-right: 10px;
		
		img{
			width: 100%;
			min-width: 100%;
			height: 150px;
		}
		
	}
	@include respond('phone-land'){
		margin-bottom: 10px;
		
		li{
			img{
				height: 120px;
			}
		}
	}
	&.gallery-grid-2 li{
		width:50%;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.team-skills{
	p{
		width:480px;
		@include respond('tab-land'){
			width:100%;
		}
	}
}
.dz-separator {
    display: inline-block;
    margin-bottom: 10px;
	margin-top: 8px;
    position: relative;
	&.m-b0 {
		margin-bottom:0;
	}
	&.style-icon {
		width: 30px;
		height: auto;
		text-align: center;
		font-size: 20px;
		color:var(--primary);
		&::after,
		&::before{
			content: "";
			position: absolute;
			top: 50%;
			left: 40px;
			width: 70px;
			height: 2px;
			background: #fff;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			border-radius: 12px;
		}
		&::before{
			left: auto;
			right: 40px;
		}
	}
	
}
.dz-separator-outer {
    overflow: hidden;
}
.dz-separator{
	&.style-1{
		height:3px;
		width:60px;
		background-color:var(--primary);
		border-radius: 8px;
	}
	
}
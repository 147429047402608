.dz-card{
	&.style-3 {
		position: relative;
		border-radius: 8px;
		overflow: hidden;
		height: 100%;
		min-height: 570px;
		background-size: cover;
		object-fit: cover;
		box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		background-position: center;
		
		&::after{
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			background: linear-gradient(359.53deg, #222222 0.4%, rgba(34, 34, 34, 0) 99.59%);
		}
		
		video{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.dz-media {
			height: 100%;
			position: relative;
			img {
				filter: grayscale(0.1);
				height: 100%;
				object-fit: cover;
				@include transitionSlow;
			}
		}
		.dz-info{
			padding: 30px;
			position: unset;
			bottom: 0;
			left: 0;
			z-index: 1;
			margin-top:auto;
			
			.dz-meta{
				margin-bottom: 0;
				ul{
					li{
						position: relative;
						i{
							color: var(--primary);
							font-size:12px;
						}
						&.dz-date{
							position: absolute;
							top: 30px;
							left: 30px;
							z-index: 1;
							background-color: var(--primary);
							color: var(--theme-text-color);
							font-weight: 500;
							border-radius: var(--border-radius-base);
							font-size: 15px;
							text-transform: uppercase;
							display: block;
							padding: 10px 25px;
							@include transitionSlow;
							
							&:hover{
								background-color: white;
								color: var(--primary);					
							}
							&::after{
								display:none;
							}
							
						}
						
						&.dz-user{
							color: var(--title);
							font-weight: 500;
							color: #fff;
							font-size:15px;
							margin-right:35px;
							span{
								color: var(--primary);
							}
							i{
								color: var(--primary);
							}
						}
						&.dz-comment{
							color: var(--title);				
							font-weight: 500;
							color: #fff;
							font-size:15px;
							i{
								color:var(--primary);
							}
						}
						&:not(:last-child)::after{
							content: "";
							height: 6px;
							width: 6px;
							position: absolute;
							right: -17%;
							top: 50%;
							border-radius: 50%;
							background-color: var(--primary);
							transform: translate(0%, -50%);
						}
					}
					@include respond('phone-land'){
						li{
							&.dz-date{
								left: 30px;
								top: 30px;
							}
						}
					}
					@include respond('phone'){
						li{
							&.dz-date{
								left: 20px;
								top: 20px;
							}
							&.dz-user{
								margin-right: 0px;
							}
							&:not(:last-child)::after{
								display:none;
							}
						}
						
					}
				}
			}
		}
		&:hover {
			.dz-media {
				img {
					transform: rotate(5deg) scale(1.2);
				}
			}
		}
		
		&.dz-card-large{
			.dz-info{
				padding: 35px;
				
				.dz-meta{
					ul{
						li{
							&.dz-date{
								top: 35px;
								left: 35px;
							}
						}
						@include respond('phone-land'){
							li{
								&.dz-date{
									left: 30px;
									top: 30px;
								}
							}
						}
						@include respond('phone'){
							li{
								&.dz-date{
									left: 20px;
									top: 20px;
								}
							}
						}
					}
				}
			}
		}
		
		@include respond('phone-land'){
			.dz-info{
				padding: 30px;
			}
		}
		@include respond('phone'){
			min-height: 450px;
			
			.dz-info{
				padding: 20px;
				
				.dz-text {
					margin-bottom: 0;
				}
			}
			.dz-category {
				margin-bottom: 20px;
			}
		}
	
		&.overlay {
			background-color: #000;
			opacity: 0.9;
			z-index: 1;
			.dz-meta {
				li{
					.post-date {
						color: rgba(255, 255, 255, 0.8);
					}
				}
			}
		}
	}
}
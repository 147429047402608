// range-slider
.range-slider{
	&.style-1{
		padding:45px 0 0;
		
		.noUi-horizontal{
			height: 8px;
			
			.noUi-handle {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				border: 3px solid var(--primary);
				background-color: #fff;
				box-shadow: none;
				top: -5px;
				
				&::before,
				&::after{
					content: none;
				}
				.noUi-tooltip {
					border: 0px;
					background: var(--primary);
					color: rgb(255, 255, 255);
					border-radius: 4px;
					font-size: 13px;
					font-weight: 600;
					padding: 2px 5px;
					bottom:30px;
					
					&::after{
						content: "";
						position: absolute;
						background-color: inherit;
						width: 10px;
						height: 10px;
						bottom: -11px;
						margin-left: -4px;
						left: 50%;
						transform: rotate(45deg) translate(-50%, -50%);
					}
				}
				&.noUi-handle-lower{
					.noUi-tooltip {
						background:var(--title);
						
						&.after{
							background-color:var(--title);
						}
					}
				}
			}
		}
		.noUi-target {
			background: #D3D3D3;
			border-radius: 4px;
			border: 0;
			box-shadow: none;
			
			.noUi-connect {
				background: var(--primary);
			}
		}
	}
}
.btn-social{
	color:#fff;
	height: 40px;
	width: 40px;
	min-width: 40px;
	line-height: 40px;
	font-size: 16px;
	text-align: center;
	border-radius:4px;
	@include transitionMedium;
	display: block;
	padding: 0;
	
	&.btn-transparent{
		background-color: rgba(255,255,255,0.2);
		
		&:hover{	
			background-color: var(--secondary);
			color:#fff;
		}
	}
	&.btn-primary{
		&:hover{	
			background-color: var(--primary);
			color:#fff;
		}
	}
	&.btn-secondary{
		&:hover{
			background-color: var(--primary);
			color:#fff;
		}
	}
	&.btn-circle{	
		border: 0;
		border-radius: 50%;
	}
	&.btn-sm{
		height: 30px;
		width: 30px;
		min-width: 30px;
		line-height: 30px;
		font-size: 14px;
	}
	&.btn-lg{
		height: 50px;
		width: 50px;
		min-width: 50px;
		line-height: 50px;
		font-size: 18px;
	}
}
.dz-social-icon{
	ul{
		margin-left: -5px;
		margin-right: -5px;
		li{
			display:inline-block;
			padding-left: 5px;
			padding-right: 5px;				
		}
	}
	&.icon-gap-10{	
		ul{
			margin-left: -10px;
			margin-right: -10px;
			li{
				padding-left: 10px;
				padding-right: 10px;
			}
		}		
	}
	&.icon-gap-5{	
		ul{
			margin-left: -5px;
			margin-right: -5px;
			li{
				padding-left: 5px;
				padding-right: 5px;
			}
		}		
	}
}
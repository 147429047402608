// Progress Bar
.progress-bx{
	overflow: hidden;
	position:relative;
	.progress{
		overflow: unset;
		
		.progress-bar{
			position: relative;
			overflow: unset;
		}
	}
	
	// Style 1
	&.style-1{
		.progress-label{
			font-weight:500;
			letter-spacing: 0.02em;
			margin-bottom:15px;
		
			.pull-end{
				position:absolute;
				right:0;
			}
		}
		.progress{
			height: 3px;
			background: #D0D0D0;;
			border-radius: 0;
			box-shadow: none;
			margin-bottom: 30px;
			overflow: visible;
			
			.progress-bar{
				box-shadow: none;
				-webkit-animation: myanimation 2s;
				animation: myanimation 2s;
			}
			&.primary{
				.progress-bar{
					background: var(--primary);
				}
			}
		}
	}
}

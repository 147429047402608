.dz-bnr-inr{
	&.style-1 {
		&::after{
			content:"";
			position:absolute;
			z-index:-1;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background: rgba(34,34,34,0.9);
		}
		
		h1 {
			color:#fff;
			font-weight: 400;
			font-size: 70px;
			line-height: 1.2;
			margin-bottom: 20px;
			font-family: 'Lobster';
			
			@include respond('laptop'){
				font-size: 60px;
			}
			
			@include respond('tab-port'){
				font-size: 50px;
				line-height: 1.1;
			}
			@include respond('phone-land'){			
				font-size: 45px;
			}
			@include respond('phone'){			
				font-size: 40px;
			}
		}
		
		.breadcrumb-row {
			z-index: 1;
			ul{
				background:var(--primary);
				li{
					color:#fff;
					a{
						color:#fff;
					}
				}
				.breadcrumb-item + .breadcrumb-item::before{
					color:#fff;
				}
			}
			@include respond('tab-port'){
				width:100%;
				ul{
					padding: 10px 18px;
					li{
						font-size:14px;
					}
				}
			}
			@include respond('phone-land'){	
				ul{
					padding:7px 14px;
					
				}
			}
		}
	}
}
.style-1.header-transparent ~ .page-content .dz-bnr-inr.style-1{
	padding-top: 50px;
	@include respond('tab-port'){
		padding-top: 30px;
	}
}

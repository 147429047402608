// Scroltop
.scroltop {
    box-shadow: -4px 4px 24px -10px var(--primary) !important;
    background: var(--primary);
    border-color: var(--primary);
    border-style: solid;
    border-width: 0;
    bottom: 15px;
    color: $white;
    cursor: pointer;
    display: none;
    height: 50px;
    line-height: 50px;
    margin: 0;
    position: fixed;
    right: 15px;
    text-align: center;
    width: 50px;
    z-index: 999;
	border-radius:6px;
    box-shadow: -4px 4px 24px -10px;
	@include transitionSlow;
	
	@include respond('phone'){
		height: 35px;
		width: 35px;
		line-height: 35px;
	}
}

.scroltop-progress{
	position: fixed;
	cursor: pointer;
	bottom: 15px;
	right: 15px;
	width: 50px;
	height: 50px;
	border-radius: 50px;
	display: block;
	opacity: 0;
	box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
	z-index: 999;
	visibility: hidden;
	transform: translateY(20px);
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
	
	&::after{
		content: "\f062";
		font-size: 18px;
		font-family: "Font Awesome 6 Free";
		font-weight: 900;
		position: absolute;
		left: 0;
		top: 0;
		height: 50px;
		cursor: pointer;
		width: 50px;
		line-height: 50px;
		color: var(--dark);
		text-align: center;
		transition: all 200ms linear;
	}
	svg path{
		fill: none;
		stroke-width: 4;
		box-sizing: border-box;
		stroke: var(--dark);
		transition: all 200ms linear;
	}
	&.active-progress{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
	&.scroltop-primary{
		&::after{
			color: var(--primary);
		}
		svg path{
			stroke: var(--primary);
		}
	}
	&.scroltop-secondary{
		&::after{
			color: var(--secondary);
		}
		svg path{
			stroke: var(--secondary);			
		}
	}
	&:hover{
		&::after{
			color: var(--primary);
		}
		svg path{
			stroke: var(--primary);
		}
	}
}